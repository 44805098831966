import React from "react"
import styled from "styled-components"

import Link from "../components/link"

const Footer = () => (
  <StyledFooter>
    <Copy>
      <span>Copyright &copy; 2018 </span>
      <Link href="https://github.com/marcebdev">MarceDev</Link>
    </Copy>
  </StyledFooter>
)

export default Footer

const StyledFooter = styled.footer`
  display: block;
  height: 2em;
  padding: 1em 2em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Copy = styled.p`
  font-size: 1.2em;
`