import React from "react"
import styled from 'styled-components'

const Anchor = styled.a`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  color: black;

  & > :not(:last-child) {
    margin-right: 0.2em;
  }

  & > svg {
    color: inherit;
    font-size: 1.6em;
  }
`

const Link = ({ href = '#', newTab, name = '', icon, className, children }) => {
  const target = newTab ? '_blank' : '';
  const rel = newTab ? 'noopener noreferrer' : ''
  const text = name && name !== '' ? name : children

  return (
    <Anchor href={href} target={target} rel={rel} icon={icon} className={className}>
      {text && <span>{text}</span>}
      {icon && React.createElement(icon)}
    </Anchor>
  )
}

export default Link;