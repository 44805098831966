import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import "../styles/reset.css"
import "../styles/main.css"
import "@fontsource/dosis/latin-300.css"
import "@fontsource/dosis/latin-400.css"
import theme from "../util/theme"
import { FaGithubAlt } from "react-icons/fa";

import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import ProjectCard from "../components/projectCard"

const skills = ['ReactJS', 'VueJS', 'TypeScript', 'JavaScript', 'CSS', 'NextJS', 'Svelte', 'C/C++']

const projects = [
  {
    name: 'realbnb',
    description: '[WIP] Peer project developing an Airbnb clone to work with NextJS, Prisma2, GraphQL, and TypeScript',
    link: 'https://github.com/shubhsk88/realbnb-frontend',
    year: 2021
  },
  {
    name: 'pleajustice',
    description: 'Web app to config & create scenarios for their simulation software',
    link: 'https://researcher.pleajustice.org/',
    year: 2020
  },
  {
    name: 'snakebacon',
    description: 'Landing page for Snake Bacon LLC',
    link: 'https://snakebacon.com/',
    year: 2018
  },
  {
    name: 'github',
    icon: { component: FaGithubAlt, color: 'white', backgroundColor: 'rgb(36 41 46)' },
    description: 'Find some of my other work on Github',
    link: 'https://github.com/marcebdev'
  }
]


const imagesQuery = graphql`
  query ImagesQuery {
    images: allFile(filter: {relativeDirectory: {eq: "projects"}}) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth:700, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
}
`

// markup
const IndexPage = () => {
  const projectImages = useStaticQuery(imagesQuery).images.edges

  function getImage(name) {
    return projectImages.find(({ node }) => node.name === name)?.node.childImageSharp.fluid ?? null;
  }

  return (
    <ThemeProvider theme={theme}>
      <SEO />
      <Header />
      <main>
        <ContentWrapper>
          <Content title="About Me" padded>
            <Bio>
              I started getting involved in web development as a senior in high school. Now as Fall 2020 graduate with intern experience I seek a more permanent position where I can learn, grow, and continue to fuel my enjoyment developing for the web!
              <br /><br />
              I'm currently peer programing an Airbnb clone to learn more about backend, and exploring some technologies including TypeScript, Prisma2, GraphQl, and NextJS.
            </Bio>
          </Content>

          <Content title="Skills" padded>
            <Skills>
              {skills.map(skill =>
                <Skill key={skill}>{skill}</Skill>
              )}
            </Skills>
          </Content>

          <Content title="Projects">



            <ProjectWrapper>
              {projects.map(project =>
                <ProjectCard key={project.name} project={project} fluid={getImage(project.name)} />
              )}
            </ProjectWrapper>
          </Content>
        </ContentWrapper>
      </main>
      <Footer />
    </ThemeProvider>
  )
}

export default IndexPage

// Styles
const ContentWrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 3em 2em 1.5em;
  font-size: 1.45em;
`

const Content = ({ title, padded, children }) => (
  <Section padded={padded}>
    <h2 id={title.toLowerCase()}>{title}</h2>
    <div>
      {children}
    </div>
  </Section>
)

const Section = styled.div`
  /* Title */
  & > :first-child {
    display: inline-block;
    font-weight: 400;

    /* Link styling */
    position: relative;
    text-decoration: none;

    &::after {
      content: "";
      position: absolute;
      bottom: -0.1em;
      left: 5%;
      width: 90%;
      height: 0.1em;
      min-height: 1px;
      max-height: 3px;
      border-radius: 20px;
      background-color: ${props => props.color || props.theme.primaryLight};
      transform-origin: center;
      transition: transform 0.25s ease;
    }

    &:hover::after {
      transform: scaleX(0);
    }
  }

  /* Content */
  & > :nth-child(2) {
    font-weight: 300;
    margin: 1.5em 0;

    @media (min-width: 576px) {
      padding: ${props => props.padded && '0 1.5em'};
    }
  }
`

const Bio = styled.p`
  text-align: Center;
  @media (min-width: 576px) {
    text-align: justify;
  }
`

const Skills = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  margin: 0 -0.25em -1em;
  & > * {
    margin-bottom: 1em;
    margin-left: 0.25em;
    margin-right: 0.25em;
  }

  @media (min-width: 576px) {
    justify-content: flex-start;
  }
`

const Skill = styled.li`
  padding: 0.2em 0.8em;
  font-size: 0.9em;
  background-color: ${props => props.theme.primaryLight};
  border-radius: 4px;
`

const ProjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: -1em;
  & > * {
    margin-bottom: 1em;
  }

  @media (min-width: calc(2 * 300px + 7em)) {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -0.5em -1em;
    & > * {
      margin-left: 0.5em;
      margin-right: 0.5em;
    }
  }
`