import React from "react"
import styled, { css } from "styled-components"

import Img from "gatsby-image"
import { FaArrowRight } from "react-icons/fa"
import Link from "../components/link"

const ProjectCard = ({ project, fluid }) => {
  const icon = project.icon;

  return (
    <Card icon={icon}>
      <Mask>
        <Description>{project.description}</Description>
        <Date>{project.year}</Date>
        <StyledLink href={project.link} newTab icon={FaArrowRight}>Visit</StyledLink>
      </Mask>
      <StyledImg fluid={fluid} alt={project.description} />
      {icon && React.createElement(icon.component)}
    </Card>
  )
}

export default ProjectCard

const Mask = styled.div`
  visibility: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0,0,0,0.5);
  border-radius: inherit;
  color: white;
`

const Description = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  text-align: center;
`

const Date = styled.p`
  position: absolute;
  bottom: 5%;
  left: 5%;
`

const StyledLink = styled(Link)`
  position: absolute;
  bottom: 5%;
  right: 5%;
  color: ${props => props.theme.primary};

  & > svg {
    font-size: 1.33em;
  }
`

const StyledImg = styled(Img)`
  height: inherit;
  width: inherit;
  max-height: 100%;
  max-width: 100%;
  border-radius: inherit;

  /*
  & > img {
    object-fit: cover;
    object-position: center center;
  }
  */
`

const Card = styled.div`
  position: relative;
  height: 300px;
  width: 300px;
  max-width: 80%;
  background-color: peachpuff;
  border-radius: 20px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02);

  &:hover ${Mask} {
    visibility: visible;
  }

  ${props => props.icon && css`
    background-color: ${props.icon.backgroundColor};

    & > svg {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 0;
      transform: translate(-50%, -50%);
      font-size: 4em;
      color: ${props.icon.color};
    }
  `}
`