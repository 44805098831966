const theme = {
  primary: '#ff5722',
  primaryLight: '#ff8a50',
  primaryDark: '#c41c00',

  secondary: '#fff',
  secondaryDark: '#ecf0f1',

  textLight: '#ecf0f1',
  textDark: '#212121'
}

export default theme

/*
:root

  //Theme Properties
  --theme-font-main: "Roboto"
  --theme-font-text: "Open Sans"
  //--theme-font-fancy: "Pacifico", cursive
  //--theme-font-semi-fancy: "Oleo Script Swash Caps", cursive
  --theme-font-fancy: "Kaushan Script", cursive

  .theme-heading
    color var(--theme-text-dark)
    font-family: var(--theme-font-main)

  .theme-fancy-subheading
    color var(--theme-text-dark)
    font-family: var(--theme-font-semi-fancy)
    font-weight 400

  .theme-fancy-heading
    color var(--theme-text-dark)
    font-family: var(--theme-font-fancy)
    font-weight 400
*/