import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import styled, { withTheme } from 'styled-components'

import { FaChevronDown } from "react-icons/fa"
import Link from "./link"
import SocialBar from "./socialBar"

// markup
const Header = () => {
  return (
    <header>
      <Banner>
        <Titles>
          <h1>Marcello Barbieri</h1>
          <h2>Web Developer</h2>
        </Titles>

        <Flex>
          <ProfilePic />
          <StyledSocialBar />
        </Flex>
      </Banner>

      <TravelLink href="#projects" />
    </header>
  )
}

export default Header

const Banner = ({ children }) => (
  <Skewed>
    <Content>
      {children}
    </Content>
  </Skewed>
)

const ProfilePic = withTheme(({ theme }) => {
  const profilePicFluid = useStaticQuery(query).file.childImageSharp.fluid
  console.log(profilePicFluid)

  return (
    <ProfileSvg viewBox="0 0 100 100">
      <defs>
        <pattern id="img" patternUnits="userSpaceOnUse" width="100" height="100">
          {/*
            <foreignObject x="-30.5" y="+1" width="150" height="100">
              <picture>
                <source type="image/webp" src={profilePicFluid.srcWebp} />
                <img type="image/jpeg" src={profilePicFluid.src} />
                <img src={profilePicFluid.src} loading="lazy" alt="My profile pic" />
              </picture>
            </foreignObject>
          */}
          <image href={profilePicFluid.src} x="-30.5" y="+1" width="150" height="100" />
        </pattern>
        <polygon id="hexagon" points='50 1 95 25 95 75 50 99 5 75 5 25' />
      </defs>
      <use href="#hexagon" fill={theme.primaryDark} stroke={theme.primaryLight} strokeWidth="2px" />
      <use href="#hexagon" fill='url(#img)' />
    </ProfileSvg>
  )
})

const query = graphql`
    query HeaderQuery {
      file(relativePath: {eq: "profile.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 750, quality: 60) {
            src
          }
        }
      }
    }
  `

// styles
const Skewed = styled.div`
  position: relative;
  /* padding: calc(70% * ${Math.tan(Math.abs(-8) * Math.PI / 180) / 2}); */
  padding-bottom: 7rem;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(${props => props.theme.primary}, ${props => props.theme.primaryLight} 90%);
    transform: skewY(-5deg);
    transform-origin: top left;
    border-bottom: 4px solid ${props => props.theme.primaryDark};
    box-shadow: 0 8.5px 8px -8px rgba(1, 1, 1, 0.45);
  }
`

const Content = styled.div`
  max-width: 70%;
  margin: 0 auto;
  position: relative;
`

const Titles = styled.div`

  padding: 1.5em 0;
  font-size: 1.2em;
  text-align: center;

  @media (min-width: 992px) {
    text-align: justify;

    margin-bottom: -2em;
    padding-bottom: 0;

    & > :not(:first-child) {
      padding-left: 1.25em;
    }
  }

  @media (min-width: 1200px) {
    margin-left: 5%;
  }
`

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ProfileSvg = styled.svg`
  height: 40vh;
`

const StyledSocialBar = styled(SocialBar)`
  margin-top: 1rem;
`

const TravelLink = styled(Link).attrs(() => ({
  icon: FaChevronDown
}))`
  display: block;
  width: min-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: -10px;
  font-size: 2em;
`