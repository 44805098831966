import React from 'react'
import styled from 'styled-components'

import { FaGithubAlt, FaLinkedinIn } from "react-icons/fa";

import Link from "./link"

const links = [
  {
    text: 'Github',
    icon: FaGithubAlt,
    url: 'https://github.com/marcebdev'
  },
  {
    text: 'LinkedIn',
    icon: FaLinkedinIn,
    url: 'https://www.linkedin.com/in/marcello-b-251024127'
  }
]

// markup
const SocialBar = ({ className }) => (
  <Links className={className}>
    {links.map(link => (
      <li key={link.text}>
        <StyledLink href={link.url} newTab icon={link.icon} />
      </li>
    ))}
  </Links>
)

export default SocialBar

// styles
const Links = styled.ul`
  width: min-content;
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > :not(:last-child) {
    margin-right: 1em;
  }
`

const StyledLink = styled(Link)`
  color: white;
  transition: color 0.4s ease;

  &:hover {
    color: #c72c41;
  }

  & > svg {
    font-size: 1.8em;
    transition: filter 0.5s ease;
  }

  & > svg:hover {
    filter: drop-shadow(0.1em 0.3em 0.2em rgba(1, 1, 1, 0.4));
  }
`